import { addErrorHandler, getAppStatus, LOAD_ERROR } from 'single-spa'

import { handleModuleLoadError, isModuleLoadError } from '@lasso/shared/utils'

if (process.env.COMMAND !== 'serve') {
  addErrorHandler((error) => {
    if (getAppStatus(error.appOrParcelName) === LOAD_ERROR) {
      handleModuleLoadError()
    }
  })

  window.addEventListener('error', (event) => {
    if (isModuleLoadError(event.error)) {
      handleModuleLoadError()
    }
  })

  window.addEventListener('unhandledrejection', (event) => {
    if (isModuleLoadError(event.reason)) {
      handleModuleLoadError()
    }
  })
}
