import { escapeRegExp } from 'lodash-es'

import { arrayify, urlJoin } from '@lasso/shared/utils'

import { AppActiveWhen, AppLocation } from '@root-config/types'

const baseUrl = process.env.BASE_URL || '/'
const basePathRegex = new RegExp(`^${escapeRegExp(baseUrl)}`)

export const normalizeLocation = (loc: AppLocation): AppLocation => {
  let pathname = urlJoin(loc.pathname, '/').replace(basePathRegex, '')

  if (pathname.startsWith('/')) {
    pathname = pathname.slice(1)
  }

  if (pathname.endsWith('/')) {
    pathname = pathname.slice(0, -1)
  }

  if (!pathname) {
    pathname = '/'
  }

  return {
    pathname,
    search: loc.search,
  }
}

export const isAppActive = (activeWhen: AppActiveWhen, location: AppLocation): boolean => {
  return arrayify(activeWhen).some((activeWhen) => {
    return typeof activeWhen === 'function' ? activeWhen(location) : location.pathname.startsWith(activeWhen)
  })
}
