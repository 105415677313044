import { urlJoin } from '@lasso/shared/utils'

// Set path to angularjs's public folder taking base url into account
// This is completely broken in webpack, so we do it here

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore import.meta.resolve is untyped
const importUrl = import.meta.resolve('@lasso/angularjs')
const url = new URL(importUrl)

// @ts-expect-error webpack nonsense
window.__webpack_public_path__ = urlJoin(url.origin, process.env.BASE_URL!, '/angularjs/')
