import { addErrorHandler, getAppStatus, LOAD_ERROR, registerApplication as registerApplicationBase } from 'single-spa'

import { hideLoader, navigateToAppUrl, showLoader, truthy } from '@lasso/shared/utils'

import { hideError, showError } from '@root-config/singleSpaError'
import { AppConfig, AppLocation } from '@root-config/types'
import { isAppActive, normalizeLocation } from '@root-config/utils'

const importApp = async (id: string) => {
  switch (id) {
    case '@lasso/root-app':
      return await import('@root-config/rootApp/main')

    case '@lasso/pages':
      return await import('@root-config/pages/main')

    default:
      return await import(/* @vite-ignore */ id)
  }
}

const registerApplication = ({ id, activeWhen, loader = 'mf', shouldHideLoader = true }: AppConfig) => {
  registerApplicationBase({
    name: id,
    app: async () => {
      if (loader) {
        showLoader(loader)
      }

      try {
        const loadedModule = await importApp(id)

        if (loader && shouldHideLoader) {
          hideLoader(loader)
        }

        return loadedModule
      } catch (error) {
        if (loader) {
          hideLoader(loader)
        }

        throw error
      }
    },
    activeWhen: (location) => isAppActive(activeWhen, normalizeLocation(location)),
  })
}

const addErrorHandling = () => {
  if (process.env.NODE_ENV !== 'development') {
    return
  }

  addErrorHandler((error) => {
    if (getAppStatus(error.appOrParcelName) !== LOAD_ERROR) {
      return
    }

    console.error(error)
    showError(error.appOrParcelName, error)

    const onAppChange = (event: Event) => {
      const appStatus = (event as CustomEvent).detail.newAppStatuses[error.appOrParcelName]

      if (appStatus === LOAD_ERROR) {
        return
      }

      hideError(error.appOrParcelName)
      window.removeEventListener('single-spa:app-change', onAppChange)
    }

    window.addEventListener('single-spa:app-change', onAppChange)
  })
}

export const registerApplications = (apps: Array<AppConfig | null>) => {
  apps.filter(truthy).forEach(registerApplication)

  addErrorHandling()
}

export const initRouter = (getRedirectPath: (location: AppLocation) => string | null) => {
  const redirect = () => {
    const path = getRedirectPath(normalizeLocation(window.location))

    if (path) {
      navigateToAppUrl(path)
    }
  }

  window.addEventListener('single-spa:routing-event', redirect)
  redirect()
}

export { start as startApplication } from 'single-spa'
