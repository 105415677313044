export const getAuthInfo = () => {
  const authInfoString = localStorage.getItem('ls.authorizationData')

  if (!authInfoString) {
    return null
  }

  try {
    return JSON.parse(authInfoString)
  } catch {
    return null
  }
}

export const getUserInfo = () => {
  const userInfoString = localStorage.getItem('ls.userInfo')

  if (!userInfoString) {
    return null
  }

  try {
    return JSON.parse(userInfoString)
  } catch {
    return null
  }
}

export const isLoggedIn = () => {
  return Boolean(localStorage.getItem('ls.userInfo') && localStorage.getItem('ls.authorizationData'))
}

export const clearUserData = () => {
  localStorage.removeItem('ls.authorizationData')
  localStorage.removeItem('ls.userInfo')
  localStorage.removeItem('ls.account')
}
