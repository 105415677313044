import '@root-config/global/setWebpackPublicPath'
import '@root-config/global/errorHandlers'
import '@root-config/global/initLoaders'

import { setLocale } from 'yup'

import { patchGlobalStorage } from '@lasso/shared/hooks'
import { defaultMessages, extendYup } from '@lasso/shared/validation'

import { config, getRedirectPath } from '@root-config/config'
import { initRouter, registerApplications, startApplication } from '@root-config/singleSpa'

import '@root-config/styles/main.css'

patchGlobalStorage()
extendYup()
setLocale(defaultMessages)

initRouter(getRedirectPath)
registerApplications(config)
startApplication()
